import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import backArrow from '../../assets/backArrow.png'

class Category extends Component {

    settings = this.props.Settings;

    state = {
        categories: [],
        load: false,
        back: false
    }

    async componentDidMount(){

        const {data} = await axios.get("https://manage.caviarmenu.com/api/parentCategories/montana");
        this.setState({ categories: data }, ()=>{
            this.setState({load: true});
        });

    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    headCover = {'backgroundImage':`url('${this.settings.introCover}')`, 'backgroundSize':'cover', 'backgroundPosition': 'center'}
    categoryCover = {'backgroundSize': 'cover', 'backgroundPosition': 'center'}

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>

                    <div className="head" style={this.headCover}>
                            <Link to={"/"}>
                                <img src={backArrow} className="backward" alt="" />
                            </Link>
                            
                            <div className="language">
                                <button type='submit' className={this.props.Language == "krd"? 'activ': ''} onClick={() => this.handleChange('krd')} > کوردی </button>
                                <button type='submit' className={this.props.Language == "ar"? 'activ': ''} onClick={() => this.handleChange('ar')}> عربی </button>
                                <button type='submit' className={this.props.Language == "en"? 'activ': ''} onClick={() => this.handleChange('en')}> English </button>
                            </div>

                        <img src={this.settings.logo} alt="" />
                    </div>

                    <div className="category-list">

                        {
                            this.state.load? this.state.categories.map( item => <Link 
                                key={item.id}
                                to={`/menu/${item.id}`} 
                                className="category-item"
                                style={ { backgroundImage :`url(${item.cover})`, ...this.categoryCover} }
                                >
                                <div className='text'>
                                    {
                                        this.props.Language == "en" ? item.name_eng : this.props.Language == "krd" ? item.name_krd : item.name_ar
                                    }
                                </div>
                            </Link>):""
                        }

                    </div>

                </div>
            </>
        );
    }
}

export default Category;